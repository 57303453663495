import Homepage from "../pages/Homepage";

function App() {
  return (
    <div className="App text-['Inter']">
      <Homepage />
    </div>
  );
}

export default App;
